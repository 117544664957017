header {
    width: 16rem;
    height: calc(100vh - 4rem);
    padding: 2rem;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
}

header img {
    width: 100%;
    margin: .4rem 0;
}

.link__container {
    display: flex;
    flex-direction: column;
    margin-top: 1.4rem;
}

.link {
    text-decoration: none;
    color: var(--gray);
    display: flex;
    align-items: center;
    font-size: large;
    font-weight: 600;
    padding: .6rem 1.2rem;
    border-radius: .6rem;
    margin: .2rem 0;
    transition: .3s;
}

.link:hover {
    background-color: var(--main-op);
}

.link i {
    font-size: x-large;
    margin-right: 1rem;
}

.link__active {
    color: var(--main);
    background-color: var(--bg2);
}

.email {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: var(--gray);
    text-decoration: none;
    transition: .3s;
}

.email i {
    font-size: x-large;
    margin-right: .6rem;
}

.email:hover {
    color: var(--main);
}

.logo__small {
    width: 60px;
    display: none;
}

@media only screen and (max-width: 1060px) {
    header {
        width: fit-content;
    }

    .logo__small {
        display: inline;
    }

    .logo__large {
        display: none;
    }

    .link, .email {
        font-size: 0;
        width: fit-content;
    }

    .link i {
        font-size: x-large;
        margin-right: 0;
    }
}

@media only screen and (max-width: 659px) {
    header {
        width: 100vw;
        height: fit-content;
        position: fixed;
        top: 100%;
        left: 0;
        flex-direction: row;
        justify-content: center;
        padding: 1rem;
        background-color: var(--bg);
        transform: translate(0, -100%);
    }

    .link__container {
        display: flex;
        flex-direction: row;
        margin-top: 1.4rem;
    }

    .link__container {
        margin-top: 0;
    }

    .logo__small {
        display: none;
    }

    .email {
        display: none;
    }
}