.validator__link {
    display: flex;
    align-items: center;
    justify-content: left;
}

.validator__link img {
    width: 26px;
    border-radius: 50%;
    margin-right: .4rem;
}