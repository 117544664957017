

.validator__details img {
    width: 50px;
    border-radius: 50%;
    margin-right: .8rem;
}

.validator__details p {
    font-size: small;
    margin-top: .2rem;
}

.validator__details a {
    font-size: small;
    margin-top: .2rem;
}

.validator__details__img {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 1.2rem;
    margin-top: 1rem;
}

.validator__details__img h4 {
    margin: 0;
}

.validator__details__img a {
    font-size: small;
}

.validator__details h5 {
    margin: 0;
    margin-top: .8rem;
}

.commission__detail {
    width: 50%;
    margin: .7rem 0;
}

.commission__detail h4 {
    margin: 0;
}

.commission__detail p {
    margin: 0;
    margin-top: .3rem;
}

.validator__staked p {
    font-size: medium !important;
}

.validator__staked img {
    margin-right: .2rem !important;
}