.tx__error {
    font-size: small;
    font-weight: 600;
    color: #F97A6F;
    background-color: #F97A6F20;

    padding: .3rem 1rem;
    border-radius: .8rem;
}

.tx__success {
    font-size: small;
    font-weight: 600;
    color: #5DD360;
    background-color: #5DD36020;
    padding: .3rem 1rem;
    border-radius: .8rem;
}

.tx__date {
    color: var(--gray);
    font-size: small;
    font-weight: 600;
    margin-left: .6rem;
}

.tx__field {
    font-weight: 500;
}

.tx table {
    font-size: small;
}

.tx__error__log {
    font-size: small;
    font-weight: 400;
    color: #F97A6F;
    background-color: #F97A6F20;

    padding: .1rem 1rem;
    border-radius: .8rem;
    margin: .6rem 0;
}

.msg {
  margin-bottom: 1.2rem;
}

.msg h5 {
    margin: .2rem 0;
    margin-top: .8rem;
}

.address__link {
    display: flex;
    align-items: center;
    justify-content: left;
}

.address__link img {
    width: 23px;
    border-radius: 50%;
    padding-right: .2rem;
}

.execute__msg {
    font-size: small;
}

.execute__msg code pre {
    margin: 0;
    background-color: var(--bg);
    overflow-x: scroll;
    font-family: 'Roboto Mono', monospace;
    padding: 1rem;
    border-radius: .6rem;
}

.tx__showmore {
    border: 0;
    background-color: transparent;
    font-size: small;
    text-decoration: underline;
    color: var(--main);
}

.tx__signmode {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: small;
    color: var(--main);
    background-color: var(--main-op);
    padding: .6rem 1rem;
    border-radius: .6rem;
    margin: .6rem 0;
}

.tx__signmode i {
    font-size: 15pt;
    margin-right: .6rem;
}

.ibc__info {
    background-color: var(--main-op);
    padding: .8rem 1rem;
    border-radius: .6rem;
    display: flex;
    align-items: center;
    justify-content: left;
}

.ibc__info .tx__field {
    padding-right: .6rem;
}

.ibc__info i {
    font-size: 15pt;
    margin-right: .6rem;
}

.ibc__info h5 {
    margin: 0;
    margin-bottom: .4rem;
}

.ibc__logo {
    width: 20px;
    margin-right: .6rem;
}

.ibc__info p {
   font-size: small;
   margin: 0;
   margin-top: .1rem;
}