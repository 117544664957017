.button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--bg2);
    border: 0;
    background-color: var(--main);
    padding: .3rem .8rem;
    border-radius: .4rem;
    font-size: 11pt;
    font-weight: 600;
    transition: .3s;
    margin-bottom: .4rem;
    margin-right: .6rem;
    display: block;
    text-decoration: none;
    width: fit-content;
}

.button:hover {
    background-color: #110042;
}

.button i {
    padding-right: .5rem;
}