.channel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.channel * {
    cursor: pointer;
}

.channel__name {
    display: flex;
    align-items: center;
}

.channel img {
    width: 35px;
    margin-right: .6rem;
}

.channel__chain h4 {
    margin: 0;
    font-size: 12pt;
    line-height: 18pt;
}

.channel__chain p {
    margin: 0;
    font-size: 10pt;
    line-height: 8pt;
    font-weight: 600;
    color: var(--gray);
}

.channel__status {
    font-size: small;
    font-weight: 600;
    color: #5DD360;
    background-color: #5DD36020;
    padding: .3rem 1rem;
    border-radius: .8rem;
}

.channel__status__container {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.halted {
    color: #F97A6F;
    background-color: #F97A6F20;
}

.channel__status__container button {
    cursor: pointer;
    border: 0;
    background-color: transparent;
    font-size: larger;
    transition: .3s;
    color: var(--text);
}

.channel__status__container .open {
    transform: rotate(180deg);
}

.channel__details {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: .4rem;
    margin-bottom: .3rem;
    height: 0;
    opacity: 0;
    transition: .3s;
}

.channel__details__container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.channel__details__open {
    height: 50px;
    opacity: 1;
}

.channel__details__line {
    width: 60px;
    margin: 0 -16px;
    z-index: 1;
}

.channel__details__chain {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg);
    width: fit-content;
    padding: .2rem 0;
    border-radius: .6rem;
    font-size: small;
    font-weight: 600;
}

.channel__left {
    padding-right: 1.4rem;
}

.channel__right {
    padding-left: 1.4rem;
}

.channel__details__chain img {
    width: 22px;
    padding: 0 .5rem;
}

.channel__client {
    color: var(--main);
    font-size: small;
    margin: 0;
}
