.search__box {
  background-color: var(--bg);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: larger;
  border-radius: 0.6rem;
  margin: 1rem 0;
  margin-bottom: 3.4rem;
}

.search__box i {
  font-size: x-large;
  padding: 0.4rem;
}

.search__box input {
  background-color: var(--bg);
  width: 100%;
  border: 0;
  outline: none;
  font-size: large;
}

.coingecko__attribution {
  margin-left: .15rem;
  font-size: small;
}
